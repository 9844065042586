body {
    background-color: black;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 2rem;
    color: greenyellow;
    font: 1.3rem Inconsolata, monospace;
    text-shadow: 0 0 5px #C8C8C8;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: repeating-linear-gradient(
        0deg,
        rgba(black, 0.15),
        rgba(black, 1) 1px,
        transparent 1px,
        transparent 2px
      );
      pointer-events: none;
    }
  }

::selection {
    background:greenyellow;
    text-shadow: none;
  }
  
pre {
    margin: 0;
  }

.red  {
    color: red
}

.blue {
  color: lightskyblue;
  cursor: pointer;
}